@tailwind base;
@tailwind components;
@tailwind utilities;

a {
  @apply underline;
}

.disabled {
  @apply cursor-not-allowed opacity-50;
}

/* Config base styles */
@font-face {
  font-family: 'AvenirLTCom-Black';
  font-display: swap;
  src:
    url('./assets/fonts/AvenirLTComBlack.eot') format('eot'),
    url('./assets/fonts/AvenirLTComBlack.woff') format('woff'),
    url('./assets/fonts/AvenirLTComBlack.woff2') format('woff2'),
    url('./assets/fonts/AvenirLTComBlack.svg') format('svg');
}

@font-face {
  font-family: 'AvenirLTCom-Light';
  font-display: swap;
  src:
    url('./assets/fonts/AvenirLTComLight.eot') format('eot'),
    url('./assets/fonts/AvenirLTComLight.woff') format('woff'),
    url('./assets/fonts/AvenirLTComLight.woff2') format('woff2'),
    url('./assets/fonts/AvenirLTComLight.svg') format('svg');
}

.grecaptcha-badge {
  display: none !important;
}
