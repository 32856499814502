.textbox[disabled],
input[disabled] {
  @apply bg-white;
}

.textbox-wrapper {
  width: fit-content;
}

.textbox-label {
  transition: all 0.2s ease;
  pointer-events: none;
  @apply absolute left-18 top-13 font-light font-base-light text-base text-gray-500 leading-7none;
}

@screen md {
  .textbox-label {
    @apply left-21 top-17;
  }
}

.textbox {
  @apply text-gray-800 border text-base border-gray-500 font-base-light font-light leading-7none;
}

.text-error {
  @apply font-base-light leading-tight text-red-600;
}

.error > input {
  @apply border-red-600;
}

.error > label {
  @apply text-red-600;
}

.textbox::placeholder {
  @apply text-gray-350;
}

.textbox.change-password::placeholder {
  @apply text-gray-800;
}

.textbox:focus ~ .textbox-label,
.error > label,
.textbox:not(:placeholder-shown) ~ .textbox-label,
.textbox.change-password ~ .textbox-label {
  @apply absolute text-xxs block top-x-1/7 left-10 bg-white px-2;
}

.textbox:focus {
  outline: none;
}

@screen md {
  .textbox:focus ~ .textbox-label,
  .error > label,
  .textbox:not(:placeholder-shown) ~ .textbox-label,
  .textbox.change-password ~ .textbox-label {
    @apply text-base top-x-1/5;
  }
}

.date-input::placeholder {
  @apply text-gray-350;
}
