.radio-wrapper {
  @apply block relative cursor-pointer pl-35 mb-12 text-base select-none;
}

.radio-wrapper input {
  @apply absolute opacity-0 cursor-pointer h-0 w-0;
}

.radio-checkmark {
  @apply absolute top-0 left-0 w-radio-cm-base h-radio-cm-base border-solid border border-gray-500 rounded-full bg-white;
}

.radio-wrapper input:checked ~ .radio-checkmark {
  @apply bg-white;
}

.radio-checkmark:after {
  @apply absolute hidden content-none;
}

.radio-wrapper input:checked ~ .radio-checkmark:after {
  @apply block;
}

.radio-wrapper .radio-checkmark:after {
  @apply absolute top-3 left-3 w-radio-cm-base-after h-radio-cm-base-after rounded-full bg-orange-400;
}
