/* Component classes button */
.btn {
  @apply text-center relative;
}

.btn-primary {
  @apply bg-orange-400 text-white font-base-light text-3xl font-normal leading-short shadow-md min-h-44;
}

.btn-secondary {
  @apply bg-green-500 text-white  text-base;
}

.btn-secondary:hover:not([disabled]),
.btn-primary:hover:not([disabled]),
.btn-layout:hover:not([disabled]),
.btn-disabled:hover:not([disabled]) {
  @apply opacity-75;
}

.btn-layout {
  @apply bg-white text-gray-800 border border-gray-800 font-base-light text-3xl leading-5relaxed text-gray-800;
}

.btn-disabled {
  @apply bg-gray-550 text-gray-400 font-base-black text-3xl font-normal leading-short min-h-44;
}

.animate-opacity {
  transition: opacity 1s ease-out;
}

.hide-alert::before {
  transform: rotate(45deg);
}

.hide-alert::after {
  transform: rotate(-45deg);
}

.hide-alert::before,
.hide-alert::after {
  @apply absolute content-none h-5 w-close-icon-after bg-white;
}
.modal-content {
  transform: translate(-50%, -50%);
}
